import './App.css';
import SearchBar from './search';
import { Redirect } from 'react-router'
import React, { useEffect, useReducer, useCallback, useRef } from 'react';


function App() {

  return (
    <div className="App">
      <header className="App-header">
        <img src={`${process.env.PUBLIC_URL}/assets/images/meowchilogo2.png`} className="App-logo" alt="logo" />
      </header>
      <SearchBar />
      <div className="tips"> HELP
        <span className="tiptext">Search with user ID by right clicking → copy id on Discord. 
        <br></br>If your avatar doesn't appear, update yourself by rating a user!</span>
      </div>
    </div>
  );

}

export default App;
