import React from 'react';
import './modal.css';
import Modal from './Modal.js'
const { render } = require("@testing-library/react");
var open = false;

const SearchBar = () => {
  const [show, setShow] = React.useState(false);
  const { search } = window.location;
  const query = new URLSearchParams(search).get('s');
  
  const [data, setData] = React.useState(null);

    React.useEffect(() => {
        fetch("https://meowchi-server.herokuapp.com/api?s=" + query)
          .then(data => data.json())
          .then((res) => {
            console.log(res);
            setData(res);
            if (!query) {
              setShow(false);
            } else {
              setShow(true);
            }
          });
          
      }, []);
      
    console.log(data);
    console.log(show);
  // action="/" method="get" className="searchform"
    return (
        <div className="search">
            <form className="searchform"> 
                <input
                    type="text"
                    id="header-search"
                    placeholder="Search user"
                    name="s"
                    className="searchbar"
                /><br></br><br></br>
                <button type="submit" className="searchbutton">Search</button>
            </form>
            <Modal show={show} handleClose={() => setShow(false)}>
              <img src={data && data[2] ? data[2] : ""} className="pfp"></img>
              <p className="username">{data && data[0] ? data[0] : "User not found"}</p>
              <p className="score">Score: {data && data[3] ? parseFloat(data[3]).toFixed(1) : "N/A"}</p>
            </Modal>
        </div>
    )
}

export default SearchBar;